@import 'client/styles/font.css';
@import 'client/styles/color.css';

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.dcm-regular {
    font-family: var(--font-name);
    font-style: normal;
    font-weight: 400;
}

.dcm-regular-italic {
    font-family: var(--font-name-italic);
    font-style: italic;
    font-weight: 400;
}

.dcm-bold {
    font-family: var(--font-name-bold);
    font-style: normal;
    font-weight: 700;
}

.dcm-bold-italic {
    font-family: var(--font-name-bold-italic);
    font-style: italic;
    font-weight: 700;
}


html {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

body {
    font-family: var(--font-name);
    font-weight: 400;
    margin: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
}

#root {
    overflow: hidden;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.menu-icon-style {
    fill: black;
    width: 24px;
    height: 24px;
}

.webstv-icon {
    fill: black;
    width: 16px;
    height: 16px;
}

::-webkit-scrollbar {
    height: 4px;
    border-radius: 8px;
    width: 9px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 8px;
}
