:root {
    --container-background-color: #ededed;
    --tertiary-color: #666666;
    --tertiary-text-color: #333333;
    --error-text-color: #ff0000;
    --DarkTheme: #000000;
    --LightTheme: #ffffff;
    --button-background-color: #098dd0;
    --secondary-text-color: #0a94d3;
    --border-color: #cccccc;
    --button-gradient-color: #0a94d6;
    --button-gradient-secoundary-color: #067cc1;
    --button-border-color: #056999;
    --text-link-color: #0a94d6;
    --tooltip-background: #e0dfde;
    --tooltip-text-color: #464646;
}
