@font-face {
    font-family: 'DCM Sans';
    src: url('../../../public/fonts/verdana.ttf');
}

@font-face {
    font-family: 'DCM Sans Bold';
    src: url('../../../public/fonts/verdana-bold.ttf');
}

@font-face {
    font-family: 'DCM Sans Italic';
    src: url('../../../public/fonts/Verdana-Italic.ttf');
}

@font-face {
    font-family: 'DCM Sans Bold Italic';
    src: url('../../../public/fonts/verdana-bold-italic.ttf');
}

:root {
    --font-name: 'DCM Sans', sans-serif;
    --font-name-bold: 'DCM Sans Bold', sans-serif;
    --font-name-italic: 'DCM Sans Italic', sans-serif;
    --font-name-bold-italic: 'DCM Sans Bold Italic', sans-serif;
}
